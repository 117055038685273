import {ActiveStep, StepId} from '../../../types/checkoutApp.types';
import {CheckoutModel} from '../../models/checkout/Checkout.model';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {BIService} from './BIService';

export class StepsManagerService {
  public completedFirstStep = false;
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly visitedSteps: StepId[] = [];
  private previousStepId?: StepId;
  private steps: StepId[] = [];
  private readonly activeStep: ActiveStep = {stepId: StepId.customerDetails, stepIndex: 0};

  constructor({siteStore, biService}: {siteStore: SiteStore; biService: BIService}) {
    this.siteStore = siteStore;
    this.biService = biService;
  }

  public readonly goToNextStep = (biData: {checkout: CheckoutModel}): void => {
    const nextStepIndex = this.activeStep.stepIndex + 1;
    this.updateStepOnStage(nextStepIndex, this.steps[nextStepIndex], biData);
  };

  public readonly getSteps = (checkout: CheckoutModel): StepId[] => {
    return [
      StepId.customerDetails,
      ...(checkout.hasShippableItems ? [StepId.deliveryMethod] : []),
      StepId.paymentAndPlaceOrder,
    ];
  };

  public get stepsList(): StepId[] {
    return this.steps;
  }

  public set stepsList(steps: StepId[]) {
    this.steps = steps;
  }

  public readonly updateStepOnStage = (stepIndex: number, stepId: StepId, biData: {checkout: CheckoutModel}): void => {
    this.sendStageExpandedBIEvent(stepId, biData.checkout);
    this.previousStepId = this.activeStep.stepId ?? /* istanbul ignore next */ undefined;
    this.activeStep.stepId = stepId;
    this.activeStep.stepIndex = stepIndex;
    !this.visitedSteps.includes(stepId) && this.visitedSteps.push(stepId);
    if (stepIndex > 0) {
      this.completedFirstStep = true;
    }
  };

  private readonly sendStageExpandedBIEvent = (stepId: StepId, checkout: CheckoutModel): void => {
    this.biService.sendStageExpanded(checkout, stepId, !this.isStepInQueue(stepId));
  };

  public readonly isStepInQueue = (stepId: StepId): boolean => {
    return this.visitedSteps.includes(stepId);
  };

  public readonly getPreviousStep = (): StepId | undefined => {
    return this.previousStepId;
  };

  public readonly getActiveStep = (): ActiveStep => {
    return this.activeStep;
  };
}
